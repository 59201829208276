<template>
  <div>
    <b-modal size="xl" class="modal-size" hide-footer v-model="modal_observation_letter" title="Observaciones de la carta">
      <b-row>
        <b-col md="10">
          <b-form-group label="Observación:">
            <b-form-input type="text" v-model="new_observation" ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label=".">
            <b-button type="button" @click="AddNewObservation" class="form-control" variant="primary">Agregar</b-button>
          </b-form-group>
        </b-col>
        
        <b-col v-for="(item, it) in observations" :key="it" md="2">
          <b-button type="button" @click="AddObservation(item.name)" class="form-control" variant="primary">{{item.name}}</b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="12">
          <hr>
        </b-col>
        

        <b-col md="12">
          <b-badge class="ml-1" @click="DeteteObservation(it)" v-for="(item, it) in detail_observation" :key="it" observations variant="secondary">{{item}} <b-button size="sm" type="button" variant="danger"><i class="fas fa-trash-alt"></i></b-button></b-badge>
        </b-col>
      </b-row>
      
    </b-modal>
    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>

<style>


</style>

<script>

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
var moment = require("moment");
import CodeToName from "@/assets/js/CodeToName";
import LoadingComponent from './../../pages/Loading'
export default {
  name: "ModalClient",
    components:{
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      modal_observation_letter: false,
      module:'Letter',
      role: 0,
      index: 1,
      new_observation:'',
      observations: [],

      detail_observation:[],
    };
  },
  created (){
  
  },
  mounted () {
    EventBus.$on('ModalLetterObservationShow', (data) => {
      this.modal_observation_letter = true;
      this.role = data.role;
      this.index = data.index;
      this.ListLetterObservation();
    });
  },
  methods: {
    ListLetterObservation,
    AddObservation,
    DeteteObservation,
    AddNewObservation,
  },
  computed: {
    ...mapState('Order',["new_order_detail","order_detail"]),
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function ListLetterObservation() {

  let id_letter = this.new_order_detail[this.index].id_letter;
  this.detail_observation = this.new_order_detail[this.index].observation
  // console.log(id_letter);
  // return false;

  this.isLoading = true;
  let url = this.url_base + "letter/letter-observation/"+id_letter;
  let me = this;
  axios({
      method: "GET",
      url: url,
      headers: {"Content-Type": "application/json",token: me.token,  module: me.module, role: me.role},
  }).then(function (response) {
      if (response.data.status == 200) {
        me.observations = response.data.result; 
      }
      me.isLoading = false; 
  })
  this.isLoading = false;
}

function AddObservation(name) {
  // this.detail_observation.push({name:name});
  this.new_order_detail[this.index].observation.push(name);
}

function DeteteObservation(index) {
  // this.detail_observation.splice(index,1);
  this.new_order_detail[this.index].observation.splice(index,1);
}

function AddNewObservation() {
  if (this.new_observation.length > 0) {
    let name = this.new_observation;
    this.new_order_detail[this.index].observation.push(name);
    this.new_observation = '';
  }
  
}


</script>
