<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <b-row>
              <b-col md="4" class="text-left">
                <strong>{{order.room_name.toUpperCase() }} | MESA #{{order.table_name }}</strong>
              </b-col>
              <b-col md="4" class="text-center">
                <strong> MOZO: {{order.moso_name }}</strong>
              </b-col>
              <b-col md="4" class="text-right">
                <strong> PEDIDO #{{order.code }}</strong>
              </b-col>
            </b-row>
            
          </CCardHeader>
          <CCardBody>
            <b-row>
              <b-col md="7" class="border">
                <b-tabs align="center" content-class="mt-3">
                  <b-tab v-for="(item, it) in letters" :key="it"  :title="item.name" >
                    <b-row>
                      <b-col class="mt-1" md="3" v-for="(letter, it1) in item.letters" :key="it1">
                        <b-button @click="AddLetter(letter.id_letter)" class="w-100 align-middle btn-letter" type="button">
                          <small>{{letter.short_name}}</small>
                        </b-button>
                      </b-col>
                    </b-row>
                  
                  </b-tab>
                </b-tabs>
              </b-col>
              <b-col md="5" class="border">
                <b-form id="Form">
                  <b-row>
                      

                      <b-col md="12" class="bg-primary-app text-center">
                        <strong>ADICIONAR</strong>
                      </b-col>
                      <b-col md="12">
                        <div class="table-responsive mt-2">
                          <table class="table table-hover table-bordered">
                            <thead>
                              <tr>
                                <th width="40%" class="text-center">Descripción</th>
                                <th width="15%" class="text-center">Tipo</th>
                                <th width="20%" class="text-center">Cantidad</th>
                                <th width="10%" class="text-center">Precio</th>
                                <th width="15%" class="text-center">Acc.</th>
                              </tr>
                            </thead>
                            <tbody v-for="(item, it) in new_order_detail" :key="it">
                              <tr>
                                <td class="text-left"> {{ item.name }}</td>
                                <td class="text-center align-middle">
                                    <b-form-select class="align-middle form-control" size="md" v-model="item.type" :options="type"></b-form-select>
                                </td>
                                <td class="text-center">
                                  <b-input-group>
                                    <b-input-group-append>
                                      <b-button size="sm" @click="UpdateQuantityDetail(it,'-')" variant="primary">-</b-button>
                                    </b-input-group-append>
                                    <b-form-input type="number" @change="UpdateNewOrderDetail(it)" class="text-right" step="any" v-model="item.quantity"></b-form-input>
                                    <b-input-group-append>
                                      <b-button size="sm" @click="UpdateQuantityDetail(it,'+')" variant="primary">+</b-button>
                                    </b-input-group-append>
                                  </b-input-group>
                                </td>
                                <td class="text-right">
                                  <small>{{item.unit_price}}</small> <br>
                                  <small>{{item.total_price}}</small>
                                </td>
                                <td class="text-center">
                                  <b-button class="btn-sm" type="button" @click="ModalLetterObservationShow(it)" variant="primary"><i class="fas fa-comment-dots"></i></b-button>
                                  <b-button class="btn-sm" type="button" @click="DeleteOrderDetail(it)" variant="danger"><i class="fas fa-trash-alt"></i></b-button>
                                </td>
                              </tr>
                              <tr v-if="item.observation.length > 0"  >
                                <td colspan="8" class="text-left">
                                  <span v-for="(item1, it1) in item.observation" :key="it1">{{ item1 + ', '}}</span>
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <th colspan="3" class="text-right">Total</th>
                                <th class="text-right">{{total_new_order}}</th>
                                <th></th>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </b-col>
                      <b-col md="6">
                      </b-col>
                      <b-col md="3">
                        <b-form-group>
                          <b-button class="w-100" size="sm" @click="CancelOrderDetail" type="button" variant="danger"><i class="fas fa-ban"></i></b-button>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group>
                          <b-button class="w-100" size="sm" @click="ValidateAddNewOrderDetail" type="button" variant="primary"><i class="fas fa-check-square"></i></b-button>
                        </b-form-group>
                      </b-col>
                      <b-col md="12" class="bg-primary-app text-center">
                        <strong>DETALLE DE PEDIDO</strong>
                      </b-col>
                      <b-col md="12">
                        <OrderDetail :id_order="order.id_order"/>
                      </b-col>
                      <b-col md="3">
                        <b-form-group>
                          <b-button class="w-100" size="sm" type="button" @click="DataPrint" variant="primary" title="Imprimir Precuenta"><i class="fas fa-print"></i></b-button>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group>
                          <b-button class="w-100" size="sm" :disabled="!Permission('OrderDelete')" type="button" @click="ValidateCancelOrder" variant="danger" title="Cancelar Pedido"><i class="fas fa-ban"></i></b-button>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group>
                          <b-button class="w-100" size="sm" :disabled="!Permission('OrderEdit')" type="button" @click="ValidateChangeOrder" variant="warning" title="Cambiar Pedido"><i class="fas fa-exchange-alt"></i></b-button>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group>
                          <b-button class="w-100" size="sm" :disabled="!Permission('OrderEdit')" type="button" @click="ModalChangeLetterTable" title="Cambiar de Mesa" variant="info"><i class="fas fa-table"></i></b-button>
                        </b-form-group>
                      </b-col>

                       <b-col md="12">
                        <br>
                      </b-col>

                      <b-col md="12" class="bg-primary-app text-center">
                        <strong>INFORMACIÓN DE LA MESA</strong>
                      </b-col>

                      <b-col md="6">
                        <b-form-group label="Fecha y Hora :">
                          <b-form-input size="sm" disabled class="text-center" :value="order.date +' '+order.hour" ></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col md="3">
                        <label>Adultos:</label>
                        <b-input-group>
                          <b-input-group-append>
                            <b-button size="sm"  @click="UpdateQuantityPaxs(1,'-','adults')" variant="primary">-</b-button>
                          </b-input-group-append>
                          <b-form-input size="sm" type="number" disabled class="text-center" step="any" v-model="order.pax_adults"></b-form-input>
                          <b-input-group-append>
                            <b-button size="sm" @click="UpdateQuantityPaxs(1,'+','adults')" variant="primary">+</b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-col>
                      <b-col md="3">
                        <label>Niños:</label>
                          <b-input-group>
                            <b-input-group-append>
                              <b-button size="sm"  @click="UpdateQuantityPaxs(1,'-','kids')" variant="primary">-</b-button>
                            </b-input-group-append>
                            <b-form-input size="sm" type="number" disabled class="text-center" step="any" v-model="order.pax_kids"></b-form-input>
                            <b-input-group-append>
                              <b-button size="sm" @click="UpdateQuantityPaxs(1,'+','kids')" variant="primary">+</b-button>
                            </b-input-group-append>
                          </b-input-group>
                      </b-col>
                     

                  </b-row>
                </b-form>
              </b-col>
            </b-row>


            <b-row class="justify-content-md-center">
              <b-col md="12">
                <br>
              </b-col>

              <b-col v-if="order.id_reservation != '0'" md="2">
                <label>.</label>
                <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                      <tbody >
                        <tr>
                          <td class="text-center">RESERVA: {{order.reference}}</td>
                          <td class="text-center">
                            <b-button type="button" @click="ViewReservation" size="sm"  variant="primary">
                              <i class="fas fa-eye"></i>
                            </b-button>
                          </td>
                          <td class="text-center">
                            <b-button type="button" @click="ConfirmDeleteReservation" size="sm"  variant="danger">
                              <i class="fas fa-trash"></i>
                            </b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                </div>
              </b-col>
              <b-col  md="2">
                <label>.</label>
                <b-link :disabled="order.id_reservation != '0'" @click="modalReservation"  class="btn form-control btn-info" >RESERVA</b-link>
              </b-col>
              <b-col md="2">
                <label>.</label>
                <b-link class="btn form-control btn-primary" :to="{ path: '/pedido/mesas' }" append >REGRESAR</b-link>
              </b-col>
            </b-row>

           
              
            
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>




     <LoadingComponent :is-visible="isLoading"/>
     <LetterObservation />
     <ModalReservation />
     <ModalDetailReservation />
    <!-- <Keypress key-event="keyup" :key-code="115" @success="Validate" /> -->

    <b-modal size="md" hide-footer v-model="modalChangeTable" class="w-100" title="Cambiar Plato a Otra Mesa">
      <b-form id="Form" @submit.prevent="ValidateChangeLetterTable">
             <b-row>
      
                <b-col md="4">
                  <b-form-group label="Mesa Origen:">
                    <b-form-input disabled type="text" v-model="order.table_name"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Mesa Nueva :">
                    <b-form-select v-model="change_letter.id_new_order" :options="tables"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Clave Maestra :">
                    <b-form-input  type="password" v-model="change_letter.master_key"></b-form-input>
                  </b-form-group>
                </b-col>

                      
             </b-row>

              <b-row  class="justify-content-md-center mt-3">
                <b-col md="5">
                  <b-button type="submit" class="form-control text-white" variant="primary" ><i class="fas fa-save"></i> Cambiar Mesa</b-button>
                </b-col>
              </b-row>
        </b-form>
    </b-modal>



      <b-modal size="md" hide-footer v-model="modalCancelLetter" class="w-100" title="Anular Pedido">
            <b-form id="Form">
              <b-row>
                <b-col md="8">
                  <b-form-group label="Motivo:">
                    <b-form-input type="text" v-model="cancel_order.reason"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Clave Maestra :">
                    <b-form-input autocomplete="off" type="password" v-model="cancel_order.master_key"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row  class="justify-content-md-center mt-3">
                <b-col md="5">
                  <b-button type="button" @click="CancelOrder" class="form-control text-white" variant="primary" ><i class="fas fa-save"></i> Anular Pedido</b-button>
                </b-col>
              </b-row>
        </b-form>
      </b-modal>


      <b-modal size="md" hide-footer v-model="modalChangeLetter" class="w-100" title="Cambiar Pedido">
            <b-form id="Form">
              <b-row>
                <b-col md="8">
                  <b-form-group label="Motivo:">
                    <b-form-input type="text" v-model="cancel_order.reason"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Clave Maestra :">
                    <b-form-input autocomplete="off" type="password" v-model="cancel_order.master_key"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row  class="justify-content-md-center mt-3">
                <b-col md="5">
                  <b-button type="button" @click="ChangeOrder" class="form-control text-white" variant="primary" ><i class="fas fa-save"></i> Cambiar Pedido</b-button>
                </b-col>
              </b-row>
        </b-form>
      </b-modal>

  </div>
</template>
<style scoped>
.btn-letter{
  height: 4rem;
}


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
input[type=number] { -moz-appearance:textfield; }

</style>
<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import LoadingComponent from './../pages/Loading'
import OrderDetail from './components/OrderDetail'
import LetterObservation from './components/LetterObservation'
import ModalReservation from './components/ModalReservation'
import ModalDetailReservation from './components/ModalDetailReservation'
import converter from "@/assets/js/NumberToLetters";
export default {
  name: "UsuarioAdd",
  props: ["id_order"],
  components:{
    Keypress: () => import('vue-keypress'),
    LoadingComponent,
    vSelect,
    OrderDetail,
    LetterObservation,
    ModalReservation,
    ModalDetailReservation,
  },
  data() {
    return {
      modalChangeTable:false,
      modalCancelLetter:false,
      modalChangeLetter:false,
      isLoading: false,
      module: 'Order',
      role:2,
      letters:[],
      order:{
        room_name:'',
        table_name:'',
        room_name:'',
        order_detail:[],
        id_reservation:'',
        reference:'',
        pax_adults:'',
        pax_kids:'',
      },
      change_letter: {
        id_order:'',
        order_detail:[],
        name:'',
        table_name:'',
        id_new_order:'',
        master_key:'',
      },
      cancel_order: {
        id_order:'',
        order_detail:[],
        reason:'',
        master_key:'',
      },
      client:{id:1,name:'Clientes varios - 00000000'},
      clients:[],
      reason:'',
      type:[
        {value:'1', text:'N'},
        {value:'2', text:'E'},
        {value:'3', text:'F'},
      ],

      category_letter: {
        name: "",
        description: "",
        photo: "",
        state: 1,
      },
      state:[
        {value:1,text:'Activo'},
        {value:0,text:'Inactivo'},
      ],
      previewImage: 'https://loisjeans.id/skin/frontend/base/default/images/catalog/product/placeholder/image.jpg',
      photo: null,
      errors: {
        name: false,
        photo:false,
      },
      validate: false,
      tables:[],
    };
  },
  mounted() {
    EventBus.$on('RefreshOrder', () => {
      // this.mLoadResetOrderDetail();
      // this.mLoadNewResetOrderDetail();
      // this.ListLetter();
      this.ViewOrder();
    });

     EventBus.$on('SendLetterFund', () => {
       console.log('comanda')
      this.SendLetterFund();
    });

    
    // this.mLoadResetOrderDetail();
    this.mLoadNewResetOrderDetail();
    this.ListLetter();
    this.ViewOrder();
  },
  methods: {
    ListLetter,
    ViewOrder,
    AddLetter,


    UpdateOrderDetailInputObservation,
    ModalLetterObservationShow,
    UpdateNewOrderDetail,
    UpdateQuantityDetail,
    DeleteOrderDetail,
    CancelOrderDetail,
    ValidateAddNewOrderDetail,
    AddNewOrderDetail,
    ModalChangeLetterTable,
    ValidateChangeLetterTable,
    ChangeLetterTable,

    ValidateCancelOrder,
    CancelOrder,

    ValidateChangeOrder,
    ChangeOrder,

    SendLetterFund,

    DataPrint,
    PrintOrder,
    PrintCommand,

    modalReservation,
    ViewReservation,
    ConfirmDeleteReservation,
    DeleteReservation,

    UpdateQuantityPaxs,

    Permission,
     ListTableActive,
   
    ...mapActions('Order',['mLoadNewAddOrderDetail','mLoadNewResetOrderDetail','mLoadTotalNewOrderDetail']),
    ...mapActions('Order',['mLoadAddOrderDetail','mLoadResetOrderDetail']),
  },

  computed: {
    ...mapState('Order',["new_order_detail","order_detail","total_new_order"]),
    ...mapState(["url_base","url_server_command"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function ListTableActive() {
  
  let me = this;
  let url = this.url_base + "order/order-actives";
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token,module: this.module,role: me.role},
  })
    .then(function (response) {
      me.tables = [{value:'',text:'- Seleccione una mesa -'}];
      if (response.data.status == 200) {
        let tables = response.data.result;
        for (let index = 0; index < tables.length; index++) {
          const element = tables[index];
          if (me.order.id_order != element.id_order) {
            me.tables.push({value:element.id_order,text:element.room_name+" | "+element.table_name})
          }
        }
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function UpdateQuantityPaxs(quantity,operation,type) {
  
  let id_order = je.decrypt(this.id_order);
  let me = this;
  let url = this.url_base + "order/update-paxs";
  let data = {
    id_order : id_order,
    operation : operation,
    type : type,
    quantity : quantity,
  }
  axios({
    method: "post",
    url: url,
    data: data,
    headers: { token: this.token,module: this.module,role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.order.pax_adults = response.data.result.pax_adults;
        me.order.pax_kids = response.data.result.pax_kids;
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function modalReservation() {
  let data = {
    id_order: this.order.id_order,
    role: this.role,
  };
  EventBus.$emit('ModalReservationShow',data);
}

function ViewReservation() {
  let data = {
    id_reservation: this.order.id_reservation,
    role: this.role,
  };
  EventBus.$emit('ModalDetailReservationShow',data);
}

function ConfirmDeleteReservation() {
  // Swal.fire({
  //   title: 'Esta seguro de eliminar la reserva ?',
  //   icon: 'warning',
  //   showCancelButton: true,
  //   confirmButtonColor: '#3085d6',
  //   cancelButtonColor: '#d33',
  //   confirmButtonText: 'Si, Estoy de Acuerdo!'
  // }).then((result) => {
  //   if (result.isConfirmed) {
  //     this.DeleteReservation();
  //   }
  // })

  this.DeleteReservation();
}

function DeleteReservation() {
  let me = this;
  let url = this.url_base + "order/delete-reservation";
  let data  = {
    id_order : this.order.id_order,
    id_reservation : this.order.id_reservation,
  }
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { token: this.token, module: this.module,role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        EventBus.$emit('RefreshOrder');
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function ViewOrder() {
  let id_order = je.decrypt(this.id_order);
  let me = this;
  let url = this.url_base + "order/view/"+id_order;
  me.mLoadResetOrderDetail();
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token,module: this.module,role: me.role},
  })
    .then(function (response) {
      
      if (response.data.status == 200) {
        me.order.id_order = response.data.result.order.id_order;
        me.order.id_table = response.data.result.order.id_table;
        me.order.id_room = response.data.result.order.id_room;
        me.order.id_warehouse = response.data.result.order.id_warehouse;
        me.order.id_client = response.data.result.order.id_client;
        me.order.room_name = response.data.result.order.room_name;
        me.order.table_name = response.data.result.order.table_name;
        me.order.moso_name = response.data.result.order.moso_name;
        me.order.code = response.data.result.order.code;
        me.order.id_reservation = response.data.result.order.id_reservation;
        me.order.reservation = response.data.result.order.reservation;
        
        me.order.pax_adults = response.data.result.order.pax_adults;
        me.order.pax_kids = response.data.result.order.pax_kids;
        me.order.date = response.data.result.order.date;
        me.order.hour = response.data.result.order.hour;
        
        me.order.observation = response.data.result.order.observation;
        me.order.subtotal = response.data.result.order.subtotal;
        me.order.igv = response.data.result.order.igv;
        me.order.discount = response.data.result.order.discount;
        me.order.total = response.data.result.order.total;
        me.order.number_to_letters = response.data.result.order.number_to_letters;
        me.order.state = response.data.result.order.state;

        me.order.order_detail = response.data.result.order_detail;

        for (let index = 0; index < me.order.order_detail.length; index++) {
          const element = me.order.order_detail[index];

           let detail = {
              id_order_detail: element.id_order_detail,
              id_letter: element.id_letter,
              code: element.code,
              name: element.name,
              igv: element.igv,
              existence_type: element.existence_type,
              unit_measure: element.unit_measure,
              type: element.type,
              quantity: element.quantity,
              unit_price: element.unit_price,
              total_price: element.total_price,
              input_observation: element.input_observation,
              observation: element.observation,
              state:element.state,
          };
          me.mLoadAddOrderDetail(detail);
        }


      }
    })
    .catch((error) => {
      console.log(error)
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function ListLetter() {
  let me = this;
  let url = me.url_base + "letter/letter-actives";
  let data = {
    id_warehouse:1,
  }
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json",token: me.token,  module: me.module, role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.letters = response.data.result;
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

function AddLetter(id_letter) {
  let me = this;
  let url = me.url_base + "letter/view/"+id_letter;
  me.isLoading = true;
  axios({
    method: "get",
    url: url,
    headers: {"Content-Type": "application/json",token: me.token,  module: me.module, role: me.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
      let detail = {
          id_letter: response.data.result.letter.id_letter,
          code: response.data.result.letter.code,
          name: response.data.result.letter.name + (response.data.result.letter.presentation.length == 0 ? '':' - '+response.data.result.letter.presentation),
          igv: response.data.result.letter.igv,
          existence_type: response.data.result.letter.existence_type,
          unit_measure: response.data.result.letter.unit_measure,
          type: '2',
          quantity: parseFloat(1),
          unit_price: parseFloat(response.data.result.letter.sale_price).toFixed(2),
          total_price: parseFloat(response.data.result.letter.sale_price).toFixed(2),
          observation: [],
          state:1,
      };
      // me.new_order_detail.push(detail)
      me.mLoadNewAddOrderDetail(detail)
      me.$notify({ group: 'alert', title: 'Sistema', text:'Se ha agregado el pedido ' + detail.name, type: 'success'})
      
    } else {
      Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
    }
    me.isLoading = false;
  })
  .catch((error) => {
    console.log(error)
    Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    me.isLoading = false;
  });
  
}

function UpdateNewOrderDetail(index) {
  this.new_order_detail[index].quantity = this.new_order_detail[index].quantity.length == 0 ? parseFloat(1): parseFloat( this.new_order_detail[index].quantity);
  this.new_order_detail[index].total_price = parseFloat(this.new_order_detail[index].quantity) * parseFloat(this.new_order_detail[index].unit_price);
  this.new_order_detail[index].total_price = parseFloat(this.new_order_detail[index].total_price).toFixed(2);
  this.mLoadTotalNewOrderDetail();
}

function UpdateQuantityDetail(index,type_operation) {
  let quantity = parseFloat(this.new_order_detail[index].quantity);
  if (type_operation == "+") {
    this.new_order_detail[index].quantity = parseFloat(quantity) + parseFloat(1);
    this.new_order_detail[index].quantity = parseFloat(this.new_order_detail[index].quantity);
    this.new_order_detail[index].total_price = parseFloat(this.new_order_detail[index].quantity) * parseFloat(this.new_order_detail[index].unit_price);
    this.new_order_detail[index].total_price = parseFloat(this.new_order_detail[index].total_price).toFixed(2);

  }
  if (type_operation == "-") {
    if (quantity <= 1) {
      this.new_order_detail[index].quantity = parseFloat(1);
      this.new_order_detail[index].total_price = parseFloat(this.new_order_detail[index].quantity) * parseFloat(this.new_order_detail[index].unit_price);
      this.new_order_detail[index].total_price = parseFloat(this.new_order_detail[index].total_price).toFixed(2);
    }else{
      this.new_order_detail[index].quantity = parseFloat(quantity) - parseFloat(1);
      this.new_order_detail[index].quantity = parseFloat(this.new_order_detail[index].quantity);
      this.new_order_detail[index].total_price = parseFloat(this.new_order_detail[index].quantity) * parseFloat(this.new_order_detail[index].unit_price);
      this.new_order_detail[index].total_price = parseFloat(this.new_order_detail[index].total_price).toFixed(2);
    }
  }

  this.mLoadTotalNewOrderDetail();
}

function UpdateOrderDetailInputObservation(index) {
    this.new_order_detail[index].input_observation = this.new_order_detail[index].input_observation == 1 ? 0:1;
}

function DeleteOrderDetail(index) {
  this.new_order_detail.splice(index,1);
    this.mLoadTotalNewOrderDetail();

}

function CancelOrderDetail() {
  this.mLoadNewResetOrderDetail();
}

function ModalLetterObservationShow(index) {
  let data = {
    index: index,
    role : this.role,
  }
  EventBus.$emit('ModalLetterObservationShow',data);
}

function ValidateAddNewOrderDetail() {
   if (this.order.id_order.length == 0) {
    this.$notify({ group: 'alert', title: 'Sistema', text:'Seleccione un pedido' + name, type: 'error'})
    return false;
  }
  if (this.new_order_detail.length == 0) {
    this.$notify({ group: 'alert', title: 'Sistema', text:'Se requeire agregar pedidos' + name, type: 'error'})
    return false;
  }

  //  Swal.fire({
  //   title: 'Esta seguro de generar el pedido?',
  //   icon: 'warning',
  //   showCancelButton: true,
  //   confirmButtonColor: '#3085d6',
  //   cancelButtonColor: '#d33',
  //   confirmButtonText: 'Si, Estoy de Acuerdo!'
  // }).then((result) => {
  //   if (result.isConfirmed) {
  //     this.AddNewOrderDetail();
  //   }
  // })

  this.AddNewOrderDetail();

}

function AddNewOrderDetail() {
 
  let me = this;
  let url = me.url_base + "order/add-order-detail";
  let data = {
    id_order:me.order.id_order,
    order_detail:me.new_order_detail,
    id_user: me.user.id_user,
  }
 
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json",token: me.token,  module: me.module, role: 2},
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.PrintCommand(response.data.result)
        // me.mLoadNewResetOrderDetail();
        // me.ViewOrder();
        me.$router.push({
          name: "OrderTable",
        });
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

function ValidateCancelOrder() {

  let detail = this.order_detail;
  let validate = true;
  for (let index = 0; index < detail.length; index++) {
    const element = detail[index];
    if (element.state == 0) {
      validate = false;
    }
  }

  if (validate) {
    this.$notify({ group: 'alert', title: 'Sistema', text:'Seleccione al menos un pedido para realizar esta operación', type: 'error'})
    return false;
  }

  this.cancel_order.master_key = '';
  this.cancel_order.reason = '';
  this.modalCancelLetter = true;

  // Swal.fire({
  //   title: 'Esta seguro de anular el pedido?',
  //   icon: 'warning',
  //   showCancelButton: true,
  //   input: 'text',
  //   inputLabel: 'Motivo de Anulación',
  //   confirmButtonColor: '#3085d6',
  //   cancelButtonColor: '#d33',
  //   confirmButtonText: 'Si, Estoy de Acuerdo!',
  // }).then((result) => {
  //   if (result.isConfirmed && result.value.length > 0) {
  //     this.CancelOrder(result.value);
  //   }
  // })

}


function CancelOrder() {
  let me = this;
  
  let url = me.url_base + "order/cancel-order-detail";

  if (me.cancel_order.reason.length == 0) {
    this.$notify({ group: 'alert', title: 'Sistema', text:'Ingrese un motivo', type: 'error'})
    return false;
  }

  if (me.cancel_order.master_key.length == 0) {
    this.$notify({ group: 'alert', title: 'Sistema', text:'Ingrese la clave maestra', type: 'error'})
    return false;
  }
  
  let data = {
    id_order:me.order.id_order,
    order_detail:me.order_detail,
    reason: me.cancel_order.reason,
    master_key: me.cancel_order.master_key,
  }
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json",token: me.token,  module: me.module, role: 4},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.modalCancelLetter = false;
        me.PrintCommand(response.data.result)
        me.ViewOrder();
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
        me.modalCancelLetter = false;
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });

}



function ValidateChangeOrder() {

    let new_detail = this.new_order_detail;
    if (new_detail.length == 0) {
      this.$notify({ group: 'alert', title: 'Sistema', text:'Seleccione al menos un pedido nuevo para realizar esta operación', type: 'error'})
      return false;
    }

    let detail = this.order_detail;
    let validate = true;
    for (let index = 0; index < detail.length; index++) {
      const element = detail[index];
      if (element.state == 0) {
        validate = false;
      }
    }
    if (validate) {
      this.$notify({ group: 'alert', title: 'Sistema', text:'Seleccione al menos un pedido para realizar esta operación', type: 'error'})
      return false;
    }

    this.cancel_order.master_key = '';
    this.cancel_order.reason = '';
    this.modalChangeLetter = true;

    // Swal.fire({
    //   title: 'Esta seguro de cambiar el pedido?',
    //   icon: 'warning',
    //   input: 'text',
    //   inputLabel: 'Motivo de Cambio',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'Si, Estoy de Acuerdo!'
    // }).then((result) => {
    //   if (result.isConfirmed && result.value.length > 0) {
    //     this.ChangeOrder(result.value);
    //   }
    // })


  
}

function ChangeOrder() {
  let me = this;
  

    if (me.cancel_order.reason.length == 0) {
    this.$notify({ group: 'alert', title: 'Sistema', text:'Ingrese un motivo', type: 'error'})
    return false;
  }

  if (me.cancel_order.master_key.length == 0) {
    this.$notify({ group: 'alert', title: 'Sistema', text:'Ingrese la clave maestra', type: 'error'})
    return false;
  }

  let url = me.url_base + "order/change-order-detail";
  let data = {
    id_order:me.order.id_order,
    new_order_detail:me.new_order_detail,
    order_detail:me.order_detail,
    master_key:me.cancel_order.master_key,
    reason:me.cancel_order.reason,
  }
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json",token: me.token,  module: me.module, role: 3},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.modalChangeLetter = false;
        me.PrintCommand(response.data.result)
        me.mLoadNewResetOrderDetail();
        me.ViewOrder();
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}


function ModalChangeLetterTable() {

    let detail = this.order_detail;
    let validate = true;
    for (let index = 0; index < detail.length; index++) {
      const element = detail[index];
      if (element.state == 0) {
        validate = false;
      }
    }
    if (validate) {
      this.$notify({ group: 'alert', title: 'Sistema', text:'Seleccione al menos un pedido para realizar esta operación', type: 'error'})
      return false;
    }

    this.ListTableActive();
    this.change_letter.id_order = this.order.id_order;
    this.change_letter.quantity = this.order.quantity;
    this.change_letter.id_new_order = '';
    this.change_letter.master_key = '';
    this.modalChangeTable = true;

    console.log(this.change_letter);
    

}

function ValidateChangeLetterTable() {

    let detail = this.order_detail;
    let validate = true;
    for (let index = 0; index < detail.length; index++) {
      const element = detail[index];
      if (element.state == 0) {
        validate = false;
      }
    }
    if (validate) {
      this.$notify({ group: 'alert', title: 'Sistema', text:'Seleccione al menos un pedido para realizar esta operación', type: 'error'})
      return false;
    }

    if (this.change_letter.id_order.length == 0) {
      this.$notify({ group: 'alert', title: 'Sistema', text:'Seleccione un pedido', type: 'error'})
      return false;
    }

    if (this.change_letter.id_new_order.length == 0) {
      this.$notify({ group: 'alert', title: 'Sistema', text:'Seleccione un nueva mesa', type: 'error'})
      return false;
    }

    if (this.change_letter.master_key.length == 0) {
      this.$notify({ group: 'alert', title: 'Sistema', text:'Ingrese la clava maestra', type: 'error'})
      return false;
    }

    // Swal.fire({
    //   title: 'Esta seguro de cambiar el plato a otra mesa?',
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'Si, Estoy de Acuerdo!'
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     this.ChangeLetterTable();
    //   }
    // })

    this.ChangeLetterTable();
  
}

function ChangeLetterTable() {

  let me = this;
  me.isLoading = true;
  let url = me.url_base + "order/change-letter-table";
  me.change_letter.order_detail = me.order_detail;
  let data = me.change_letter;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json",token: me.token,  module: me.module, role: 3},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.mLoadNewResetOrderDetail();
        me.ViewOrder();
        me.modalChangeTable = false;
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

function SendLetterFund() {
  let me = this;
  let url = me.url_base + "order/send-letter-fund";
  let data = {
    id_order:me.order.id_order,
  }
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json",token: me.token,  module: me.module, role: 2},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.PrintCommand(response.data.result)
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

function DataPrint() {
  let id_order = je.decrypt(this.id_order);
  let me = this;
  let url = this.url_base + "order/data-print/"+id_order;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token,module: this.module,role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.PrintOrder(response.data.result);
      }
    })
    .catch((error) => {
      console.log(error)
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function PrintCommand(info) {
  let url = this.url_server_command;
  var data = new FormData(); 
  data.append("data",JSON.stringify(info));
  axios({
    method: "POST",
    url: url,
    data:data,
    headers: {
      "Content-Type": "application/json",
      "Accept":"*/*",
    },
  })
    .then(function (response) {
     
    })
    .catch((error) => {
      console.log(error);
    });
}


function PrintOrder(info) {
  let url = 'http://localhost/print/consumirapi.php';
  var data = new FormData(); 
  data.append("data",JSON.stringify(info));
  axios({
    method: "POST",
    url: url,
    data:data,
    headers: {
      "Content-Type": "application/json",
      "Accept":"*/*",
    },
  })
    .then(function (response) {
     
    })
    .catch((error) => {
      console.log(error);
    });
}


function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}
  
</script>
